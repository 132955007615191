import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import MessageDashboardList from '@/core/components/messages/MessageDashboardList.vue';
import MessageLayoutContainerSlot from '@/core/components/messages/MessageLayoutContainerSlot.vue';
import MessageProjectMiniDetailsComponent from '@/core/components/messages/MessageProjectMiniDetailsComponent.vue';
import MessagesContactsComponent from '@/core/components/messages/MessagesContactsComponent.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { MessageStateEnum } from '@/core/packages/shared-library';
// import CreateMicroTaskModal from '@/core/components/messages/modal/CreateMicroTaskModal.vue';
import { MESSAGES_STORE } from '@/store/modules/messages';
import { ATTACHMENT_CACHE_STORE } from '../../../store/modules/attachment-cache';
import { USER_MESSAGES_CACHE } from '../../../store/modules/attachment-cache/constants';
import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
import { PROJECTS_STORE } from '../../../store/modules/projects';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'messages-page',
    components: {
        MessagesContactsComponent,
        MessageDashboardList,
        MessageLayoutContainerSlot,
        MessageProjectMiniDetailsComponent,
        Loader,
    },
    data() {
        return {
            loadingMessageRooms: true,
            initializing: false,
            messageDashboardIsLoaded: false,
            messageDashboardSidebarIsLoaded: false,
            userMessageRooms: [],
            selectedProject: null,
            selectedUserMessageRoom: null,
            filterChatRooms: null,
            chatRooms: [],
            allDataLoaded: false,
            subscribedRoomChannels: [],
            defaultUserMessageRoom: {
                id: 0,
                project: null,
                fromUser: null,
                toUser: null,
            },
            originalUserMessageRooms: [],
            isSearchTriggered: false,
            viewContactList: false,
            acceptedProjectMembers: []
        };
    },
    created() {
        this.initialize();
    },
    beforeUnmount() {
        this.unbindSubscribedRoomChannels();
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user', 'isHosted', 'hasMainLayoutInitialized']),
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(['isTradesperson', 'isProjectOwner', 'isLoading']),
        ...mapGetters(MESSAGES_STORE, ['getOpenCreateMicroTaskModal']),
    },
    watch: {
        selectedUserMessageRoom: {
            immediate: true,
            deep: true,
            handler(newval) {
                if (newval) {
                    this.selectedUserMessageRoom = newval;
                }
            }
        },
    },
    methods: {
        ...mapActions(['setIsLoading']),
        ...mapActions(MESSAGES_STORE, [
            'getUserMessageRooms',
            'getGroupContacts',
            'createUserMessage',
            'removeMemberFromChatRoom',
            'addMemberToChatRoom',
            'setUnreadUserMessagesByUserMessageRoom',
            'setTotalNewMessages',
            'createUserMessageRoomAttachment',
            'createUserMessageRoom',
            'createUserGroupMessageRoom'
        ]),
        ...mapActions(USERS_STORE, ['initializeUserState']),
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId']),
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachment']),
        setLoaderFalse() {
            setTimeout(() => {
                this.initializing = false;
            }, 300);
        },
        async initialize(setRoomId = null) {
            await this.initializeUserState().then(() => {
                this.initializing = true;
            });
            const { id: userId } = this.user;
            const authId = this.authenticatedUser.userId;
            // disable for global for now
            await this.getGroupContacts(userId).then((contacts) => {
                this.acceptedProjectMembers = contacts;
            }).catch(() => { });
            let getUserMessageRoomsRequest = await this.getUserMessageRooms(userId).catch(() => { });
            if (this.isHosted) {
                const getOwnUserMessageRoomsRequest = await this.getUserMessageRooms(authId).catch(() => { });
                const mergedRooms = [...getUserMessageRoomsRequest, ...getOwnUserMessageRoomsRequest];
                const set = new Set();
                getUserMessageRoomsRequest = mergedRooms.filter((item) => {
                    if (!set.has(item.id)) {
                        set.add(item.id);
                        return true;
                    }
                    return false;
                }, set);
            }
            if (getUserMessageRoomsRequest) {
                // map data to sidebar list
                const getProjectDetailsPromises = [];
                // we need to filter this out for hosted
                const rooms = getUserMessageRoomsRequest.filter((room) => {
                    return room.isGroup || (room.fromUser.id === authId || room.toUser.id === authId);
                });
                this.userMessageRooms = rooms.map((userMessageRoom) => {
                    const extend = {
                        ...userMessageRoom,
                        messagesIsLoaded: true,
                        members: [],
                        totalNewMessages: 0,
                    };
                    this.subscribeRoom({ roomId: userMessageRoom.id });
                    if (userMessageRoom?.project?.refId) {
                        getProjectDetailsPromises.push(this.getProjectByRefId(userMessageRoom.project?.refId).catch(() => { }));
                    }
                    return extend;
                });
                const getProjectDetailsResponses = await Promise.all(getProjectDetailsPromises);
                if (getProjectDetailsResponses) {
                    getProjectDetailsResponses.forEach((response, index) => {
                        if (response) {
                            this.userMessageRooms[index] = {
                                ...this.userMessageRooms[index],
                                project: response,
                            };
                        }
                    });
                }
                if (this.userMessageRooms?.length) {
                    let [firstUserMessageRoom] = this.userMessageRooms;
                    const redirectedRoomId = this.$route.query?.roomId;
                    if (redirectedRoomId) {
                        const queryRoomId = redirectedRoomId;
                        const redirectRoom = this.userMessageRooms.filter((room) => room.id === parseInt(queryRoomId, 10));
                        if (redirectRoom.length) {
                            [firstUserMessageRoom] = redirectRoom;
                        }
                    }
                    if (setRoomId) {
                        const redirectRoom = this.userMessageRooms.filter((room) => room.id === setRoomId);
                        if (redirectRoom.length) {
                            [firstUserMessageRoom] = redirectRoom;
                        }
                    }
                    this.selectedUserMessageRoom = firstUserMessageRoom;
                    this.selectedProject = this.selectedUserMessageRoom?.project;
                }
                this.originalUserMessageRooms = this.userMessageRooms;
            }
            else {
                /// trigger error fetching user rooms list
                this.$notify.error({
                    message: 'Error in fetching messages at the moment. Please refresh the page.',
                });
            }
            this.messageDashboardIsLoaded = true;
            this.messageDashboardSidebarIsLoaded = true;
            this.loadingMessageRooms = false;
            // (this as any).setLoaderFalse();
            this.setIsLoading(false);
        },
        async onSaveUserRoom(value) {
            const { userId } = this.authenticatedUser;
            const formData = {
                project: null,
                fromUser: userId,
                toUser: value,
                isDirect: 1
            };
            await this.createUserMessageRoom({ userId, formData }).then(async (response) => {
                await this.initialize(response.id);
            }).catch(() => { });
        },
        async onSaveGroupUserRoom(values) {
            const { user } = this;
            const formData = {
                projectId: null,
                users: [],
                groupName: '',
                groupAvatar: ''
            };
            values.forEach((item) => {
                formData.users.push(item.userId);
                if (!formData.groupName) {
                    formData.groupName = item.groupName;
                }
                if (!formData.groupAvatar) {
                    formData.groupAvatar = item.groupAvatar;
                }
            });
            await this.createUserGroupMessageRoom({ userId: user.id, formData }).then(async (response) => {
                await this.initialize(response.id);
            }).catch(() => { });
        },
        unbindSubscribedRoomChannels() {
            if (this.subscribedRoomChannels.length) {
                this.subscribedRoomChannels.forEach((channel) => {
                    // Unbind all events from channel
                    channel.unbind();
                    this.$pusher.unsubscribe(channel.name);
                });
                this.subscribedRoomChannels = [];
            }
        },
        subscribeRoom({ roomId }) {
            // pusher's subscribe channel `presence-messages-room-${roomId}-${toUserId}`
            const roomChannel = this.$pusher.subscribe(`presence-messages-room-${roomId}`);
            roomChannel.bind('pusher:subscription_succeeded', ({ members }) => {
                if (Object.values(members)) {
                    Object.values(members).forEach((member) => {
                        this.appendMemberToRoom(member, roomId);
                    });
                }
            });
            roomChannel.bind('pusher:subscription_error', () => {
            });
            roomChannel.bind('new.message', (message) => {
                this.appendMessageToList(message, message.userMessageRoom.id);
                this.filterContactItemIfHasNewMessage(message.userMessageRoom.id);
                // this.selectUserMessageRoom(this.selectedUserMessageRoom);
            });
            roomChannel.bind('new.message-attachment', (message) => {
                this.appendMessageAttachmentToList(message, message.userMessageRoom.id);
                this.filterContactItemIfHasNewMessage(message.userMessageRoom.id);
            });
            roomChannel.bind('pusher:member_added', (member) => {
                this.appendMemberToRoom(member, roomId);
            });
            roomChannel.bind('pusher:member_removed', (member) => {
                this.removeMemberFromUserMessageRoom(member, roomId);
            });
            this.subscribedRoomChannels.push(roomChannel);
        },
        appendMemberToRoom(member, referenceId) {
            this.addMemberToChatRoom({
                member,
                referenceId,
                userMessageRooms: this.userMessageRooms,
                callback: this.appendMemberToChatRoom,
            });
        },
        appendMemberToChatRoom(foundChatRoom, member) {
            this.userMessageRooms[this.userMessageRooms.indexOf(foundChatRoom)].members.push(member);
        },
        removeMemberFromUserMessageRoom(member, referenceId) {
            this.removeMemberFromChatRoom({
                member,
                referenceId,
                userMessageRooms: this.userMessageRooms,
                callback: this.popMemberFromChatRoom,
            });
        },
        popMemberFromChatRoom(findChatRoomByReference, findMemberFromProjectQuoteMembers) {
            this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomByReference)].members.splice(this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomByReference)].members.indexOf(findMemberFromProjectQuoteMembers), 1);
        },
        async appendMessageAttachmentToList(messageAttachmentRecord, messageRoomId) {
            const findChatRoomById = this.userMessageRooms.find((p) => p.id === messageRoomId);
            const { attachment, originalName } = messageAttachmentRecord;
            const liveAttachment = await this.getAttachment({
                name: USER_MESSAGES_CACHE,
                attachment,
                originalName,
                includeUrl: true,
            });
            const newMessageAttachmentRecord = { ...messageAttachmentRecord, ...liveAttachment };
            if (findChatRoomById) {
                const findIfExists = this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomById)].userMessageAttachments.find((m) => m.id === messageAttachmentRecord.id);
                if (findChatRoomById
                    && findChatRoomById?.id === this.selectedUserMessageRoom?.id) {
                    this.selectedUserMessageRoom.userMessageAttachments.push(newMessageAttachmentRecord);
                }
                else if (findIfExists) {
                    this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomById)].userMessageAttachments.push(newMessageAttachmentRecord);
                }
            }
        },
        async appendMessageToList(messageRecord, roomId) {
            const findChatRoomById = this.userMessageRooms.find((p) => p.id === roomId);
            if (findChatRoomById) {
                const findIfExists = this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomById)].userMessages.find((m) => m.messageId === messageRecord.messageId);
                if (findChatRoomById
                    && findChatRoomById?.id === this.selectedUserMessageRoom?.id) {
                    this.selectedUserMessageRoom.userMessages.push(messageRecord);
                }
                else if (!findIfExists) {
                    this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomById)].userMessages.push(messageRecord);
                }
            }
            if (messageRecord?.fromUser.id !== this.authenticatedUser?.userId) {
                this.selectedUserMessageRoom.totalNewMessages = this.getAllUnreadMessages();
            }
        },
        async selectUserMessageRoom(userMessageRoom) {
            if (userMessageRoom) {
                this.messageDashboardIsLoaded = false;
                this.selectedUserMessageRoom = userMessageRoom;
                this.selectedProject = this.selectedUserMessageRoom?.project;
                /* if (!(this as any).selectedUserMessageRoom.messagesIsLoaded) {
                  (this as any).selectedUserMessageRoom.messages = await (this as any).$store.dispatch(`${MESSAGES_STORE}/getMessageHistoryByRoom`, { roomId });
                } */
                await this.loadUnreadMessages();
                this.messageDashboardIsLoaded = true;
            }
        },
        async loadUnreadMessages() {
            const totalUnreadMessages = this.getAllUnreadMessages();
            const totalSelectedUserMessageRoomUnreadMessages = this.selectedUserMessageRoom.userMessages.filter((message) => message.state === MessageStateEnum.UNREAD
                && message.fromUser.id !== this.authenticatedUser?.userId);
            if (totalUnreadMessages && totalSelectedUserMessageRoomUnreadMessages.length) {
                await this.setUnreadMessagesList();
            }
            this.selectedUserMessageRoom.totalNewMessages = totalUnreadMessages;
            this.setTotalNewMessages(this.getAllUnreadMessages());
        },
        async setUnreadMessagesList() {
            const unreadMessagesList = await this.setUnreadUserMessagesByUserMessageRoom({
                userId: this.user.id,
                roomId: this.selectedUserMessageRoom.id,
            });
            this.userMessageRooms[this.userMessageRooms.indexOf(this.selectedUserMessageRoom)].userMessages = unreadMessagesList;
            this.selectedUserMessageRoom.userMessages = unreadMessagesList;
        },
        getAllUnreadMessages() {
            let total = 0;
            if (this.userMessageRooms && this.userMessageRooms.length) {
                this.userMessageRooms.forEach((room) => {
                    if (room) {
                        total += room?.userMessages.reduce((acc, message) => {
                            return message.state === MessageStateEnum.UNREAD
                                && message?.fromUser?.id !== this.authenticatedUser?.userId
                                ? acc + 1
                                : acc;
                        }, 0);
                    }
                });
            }
            return total;
        },
        async saveNewMessage(payload) {
            const { userId } = this.authenticatedUser;
            const { id } = this.selectedProject;
            const formData = {
                ...payload,
                projectId: id,
            };
            const messageRecord = await this
                .createUserMessage({ userId, formData })
                .catch(() => {
                this.$notify.error({
                    title: 'Error Messages',
                    message: 'Message has not been successfully sent. Please try again.',
                });
            });
            return messageRecord;
        },
        filterContactItemIfHasNewMessage(messageRoomId) {
            const index = this.userMessageRooms.findIndex((messageRoom) => {
                return messageRoom.id === messageRoomId;
            });
            this.userMessageRooms.unshift(this.userMessageRooms.splice(index, 1)[0]);
        },
        onChangeFilterUserMessageRooms(keyword) {
            this.userMessageRooms = this.originalUserMessageRooms;
            const { userMessageRooms: list } = this;
            const searchedKeyword = keyword.toLowerCase();
            const newList = [];
            if (list && list.length && searchedKeyword) {
                list.forEach((item) => {
                    const { fromUser, project } = item;
                    const { firstName, lastName } = fromUser;
                    const { refId, name } = project;
                    if (firstName.toLowerCase().indexOf(searchedKeyword) > -1
                        || lastName.toLowerCase().indexOf(searchedKeyword) > -1
                        || refId.toLowerCase().indexOf(searchedKeyword) > -1
                        || name.toLowerCase().indexOf(searchedKeyword) > -1) {
                        newList.push(item);
                    }
                });
            }
            if (newList.length || searchedKeyword) {
                this.userMessageRooms = newList;
            }
            if (this.userMessageRooms.length) {
                const [firstUserMessageRoom] = this.userMessageRooms;
                this.selectedUserMessageRoom = firstUserMessageRoom;
            }
            this.isSearchTriggered = !(this.userMessageRooms.length > 0);
        },
        doSubmitUploadedAttachment(file, cb) {
            const { userId } = this.authenticatedUser;
            const userMessageRoomId = this.selectedUserMessageRoom?.id;
            this
                .createUserMessageRoomAttachment({ userId, userMessageRoomId, file })
                .then((response) => {
                if (response) {
                    // (this as any).selectedUserMessageRoom.userMessageAttachments.push(response);
                    cb(response);
                }
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Error Uploading File Attachment',
                    message: e
                        ? e?.response?.message
                        : 'File attachment has not been successfully sent. Please try again.',
                });
            });
        },
        viewContacts() {
            this.viewContactList = !this.viewContactList;
        },
    },
});
